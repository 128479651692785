import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import { Message,MessageBox } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import { searchStringToArray } from '@/utils/index';
import i18n from '@/assets/i18n/index'	
// 数字跳动效果组件
import VueAnimateNumber from 'vue-animate-number'
// 剪贴板
import VueClipBoard from 'vue-clipboard2'

Vue.use(MintUI)
Vue.use(vueAnimateNumberMin)
Vue.use(VueClipBoard)
// Vue.use(ElementUI)
// 兼容低版本	
Vue.use(ElementUI,{
	i18n:(key,value) => i18n.t(key,value)
})

// 重置信息提示功能
import { message } from '@/components/reset/resetMessage';
// 引入公共css 样式
import '@/assets/common.css'
import vueAnimateNumberMin from 'vue-animate-number'

axios.interceptors.request.use(function (config) {
  let token = window.sessionStorage.getItem('token')
  let url = config.url
  if (url.indexOf('login') !== -1) {
	  if(url.indexOf('/baseinfo/loginInfo') !== -1){
			
		}else{
			return config
		}	
  }
  if (url.indexOf('captchaImage') !== -1) {
    return config
  }
  if (!token) {
    return this.$router.push({
      path: '/login'
    })
  } else {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 路由守卫
router.beforeEach((to, from, next) => {
  window.sessionStorage.setItem('index', to.path.substr(1))
  if (to.path === "/login") return next()  //如果访问的是登陆页， 直接放行

  const token = window.sessionStorage.getItem('token') //从sessionStorage 中 获取到保存的 token 值

  if (!token) return next('/login') //没有token 的话 强制跳转登陆页面
  next()
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code === 401) {
		MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
		  confirmButtonText: '重新登录',
		  cancelButtonText: '取消',
		  type: 'warning'
		}
		).then(() => {
		  router.push({
		    path: '/login'
		  })
			return;
		})
  }

  if (response.status === 200) {
    return response.data
  } else {
    Promise.reject(error);
		// 2023-1-11 zpy 显示错误信息，可关闭
		message({
			showClose:true,
			type:'error',
			duration:0,
			message: error,
		})
  }
}, function (error) {
  // 对响应错误做点什么
  // return Promise.reject(error);
	message({
		showClose:true,
		type:'error',
		duration:0,
		message: error,
	})
});

// 正式
// axios.defaults.baseURL = 'https://vip.dlvd.com' 

axios.defaults.baseURL = 'https://cnvip.fleetan.com'

// axios.defaults.baseURL = 'http://rvip.dlvd.com'

// axios.defaults.baseURL = 'https://vip.fleetan.com'
 
// 业务
 // axios.defaults.baseURL = "http://172.18.0.34:8081"	
 // axios.defaults.baseURL = "http://172.18.1.12:8081"
 // axios.defaults.baseURL = "http://192.168.1.166:8081"
 // axios.defaults.baseURL = "http://172.18.0.202:8081"

// 登录
 // axios.defaults.baseURL = "http://172.18.0.34:7002"	
// axios.defaults.baseURL = "http://172.18.1.12:7002"	
// axios.defaults.baseURL = "http://192.168.1.166:7002"	
// axios.defaults.baseURL = "http://172.18.0.202:7002"

// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// 接口请求响应时间
axios.defaults.timeout = 90000

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$baseUrl = axios.defaults.baseURL
Vue.prototype.strToArr = searchStringToArray
Vue.prototype.$message = Message
Vue.prototype.$store = store

new Vue({
  router,
  store,
	i18n,
  render: h => h(App)
}).$mount('#app')
